<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>

    <div class="content-container flex flex-direction align-center justify-center ">
      <div class="img-box">
        <img src="https://exhoss40.szcreativeweek.com/prod/register20241216.jpg" alt="观众预登记">
      </div>

      <!-- 观众预登记 -->
      <div v-if="false" class="pre-container">
        
        <h1>观众预登记</h1>
        <div class="section">
          <div class="title">观众预登记优惠</div>
          <ul class="ul1">
            <li>门票优惠：即日起至2025年3月15日23:59止，免费获取四日通票（原价150元），本人展期可进出多次。</li>
            <li>便捷入场：通过预登记，观众可获取电子参观证，直接进入展馆（电子参观证仅限本人使用）。</li>
          </ul>
        </div>
        <!-- <a href="https://reg39.szcreativeweek.com/h5/code?l=A45043-513E49" class="button text-bold" target="_blank" rel="nofollow noopener noreferrer">即刻预登记</a> -->
        <div class="section">
          <div class="title">入场须知</div>
          <ul class="ul2">
            <li>1. 所有观众均需实名登记。请如实填写个人身份信息，用手机号码登记，携带有效身份证件（身份证、护照或探亲许可证）到现场核验。</li>
            <li>2. 展览面向专业观众，十八岁以下人士谢绝入场。</li>
            <li>3. 注册成功后获得的二维码仅供个人使用，不得转让给他人。</li>
          </ul>
        </div>
        
        <div class="section">
          <div class="title">联络我们</div>
          <div class="content">
            <a href="mailto:luoliting@szfa.com">luoliting@szfa.com</a>
          </div>
        </div>
      
      </div>
      
      <!-- 参观团预登记 -->
      <div v-if="false" class="group-container">
        <h1>参观团预登记</h1>
        <div class="section">
          <div class="title">参观团介绍</div>
          <div class="content">
            除了个人参观服务，我们也提供团队参观服务。为免除现场繁复的登记手续及享用参观团礼遇，深圳家居设计周现诚邀各行业协会、企业组团参观。
          </div>
        </div>
        <div class="section">
          <div class="title">参观团礼遇</div>
          <ul class="ul1">
            <li>一对一专人接待和便捷的入场通道</li>
            <li>由大会专业摄影师拍摄团体照</li>
            <li>免费往返班车安排（展馆行程100公里以内，人数30或以上）</li>
          </ul>
        </div>
        <div class="section">
          <div class="title">参观团申请</div>
          <div class="sub-title">参观团资格</div>
          <div class="content">
            <div>参观团成员必须是已具规模的家居上下游行业龙头买家</div>
            <div>协会团：企业数量7家或以上，人数15人及以上</div>
            <div>企业团：人数15人及以上</div>
          </div>
          <div class="sub-title">参观团申请表下载</div>
          <div class="content">
            请点击此 <a href="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/Overseas%20Group%20Application%20Form.xlsx" download="参观团申请表.xlsx" target="_blank" rel="nofollow">下载</a> 申请表格填写并回传到 <a href="mailto:luoliting@szfa.com">luoliting@szfa.com</a>。
          </div>
        </div>
        <div class="section">
          <div class="title">联络我们</div>
          <div class="content">
            <a href="mailto:luoliting@szfa.com">luoliting@szfa.com</a>
          </div>
        </div>
      </div>


    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';

export default {
  // name: '展会介绍',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  metaInfo: {
    // title: 'My Example App', // set a title
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
    ],
  },
  data() {
    return {
      current: 'register',
    }
  },
  created() {

  },
  methods: {

  },
}
</script>

<style scoped lang="less">
@purplish-red: #E4007F; // 紫红色
@cyan: #00ABA5; // 紫红色
 
// 移动端
@media screen and (max-width: 1023px) {

  .content-container {
    width: 375px;
    margin: 5px auto 15px;
    
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      margin-bottom: 20px;
      
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }

    .group-container,
    .pre-container {
      width: 100%;
      padding: 0 20px;
      margin-bottom: 45px;
      
      h1 {
        font-family: 'SourceHanSansCN-Medium';
        text-align: center;
        margin-bottom: 24px;
        font-size: 16px;
      }
      .title {
        font-family: 'SourceHanSansCN-Bold';
        font-size: 14px;
        margin: 5px 0 10px;
      }
      .sub-title {
        font-family: 'SourceHanSansCN-Medium';
        font-size: 12px;
        margin: 5px 0;
      }
      
      .ul1,
      .ul2 {
        font-size: 12px;
        
        li {
          margin-bottom: 5px;
        }
      }
      .ul1 {
        list-style: disc;
        list-style-position: inside;
      }
      
      .button {
        display: block;
        background-color: @purplish-red;
        color: #fff;
        border: none;
        width: 180px;
        padding: 6px 0;
        margin: 18px auto;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
      }
      
      .section {
        margin-bottom: 20px;
        
        .content {
          font-size: 12px;
          margin-bottom: 15px;
          
          a {
            color: #409eff;
          }
        }
        
      }
      
    }


  }
  
}

// PC
@media screen and (min-width: 1024px) {

  .content-container {
    width: 240px;
    margin: 5px auto 15px;
    
    .img-box {
      background-color: #ededed;
      width: 100%;
      margin-bottom: 5px;
      
      img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 1px;
      }
    }

    .group-container,
    .pre-container {
      width: 200px;
      margin: 0 auto 15px;
      
      h1 {
        font-family: 'SourceHanSansCN-Medium';
        text-align: center;
        margin-bottom: 8px;
        font-size: 6px;
      }
      .title {
        font-family: 'SourceHanSansCN-Bold';
        font-size: 3.6px;
        margin: 1px 0;
      }
      .sub-title {
        font-family: 'SourceHanSansCN-Medium';
        font-size: 3px;
        margin: 1px 0;
      }
      
      .ul1,
      .ul2 {
        font-size: 3px;
        
        li {
          margin-bottom: 1px;
        }
      }
      .ul1 {
        list-style: disc;
        list-style-position: inside;
      }
      
      .button {
        font-family: 'SourceHanSansCN-Medium';
        display: block;
        background-color: @purplish-red;
        color: #fff;
        border: none;
        width: 60px;
        padding: 2px 0;
        margin: 6px auto;
        font-size: 5px;
        text-align: center;
        border-radius: 1.2px;
        cursor: pointer;
      }
      
      .section {
        margin-bottom: 5px;
        
        .content {
          font-size: 3px;
          margin-bottom: 3px;
          
          a {
            color: #409eff;
          }
        }
        
      }
      
    }
    

  }
  
}

</style>